import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import '../css/Header.css';
import logo from '../assets/logo.webp';

function Header({ onHomeClick, onServicesClick, onAboutMeClick, onWorkExperienceClick, isOnHomePage }) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);
  const navigate = useNavigate();

  // Toggle mobile navigation
  const handleNavToggle = () => {
    setIsNavOpen((prev) => !prev);
  };

  // Navigation handler to handle page scroll and navigation
  const handleNavigation = (scrollFunction) => {
    if (isOnHomePage) {
      scrollFunction();
    } else {
      navigate('/');
      setTimeout(() => {
        scrollFunction();
      }, 300); // Delay to ensure page transition before scrolling
    }
    setIsNavOpen(false);
  };

  // Handle click outside of the nav to close it
  const handleClickOutside = useCallback((e) => {
    if (navRef.current && !navRef.current.contains(e.target) && isNavOpen) {
      setIsNavOpen(false);
    }
  }, [isNavOpen]);

  // Add and clean up the event listener for clicking outside
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <header className="header">
      <Helmet>
        <title>Usman Sultan - Animator, Web App & Blockchain Developer</title>
        <meta name="description" content="Usman Sultan offers expert animation, web app development, and blockchain solutions. Explore services and learn more about my skills." />
      </Helmet>
      {/* Mobile navigation toggle (hamburger or cross icon) */}
      <div className="nav-toggle-label" onClick={handleNavToggle}>
        {isNavOpen ? (
          <span className="close-icon">&#x2716;</span> // Cross (close) icon
        ) : (
          <>
            <span className="hamburger"></span>
            <span className="hamburger"></span>
            <span className="hamburger"></span>
          </>
        )}
      </div>

      {/* Logo with navigation to Home */}
      <Link to="/" className="logo-link" onClick={onHomeClick}>
        <img src={logo} alt="Usman's Logo" className="logo" />
      </Link>

      {/* Navigation links */}
      <nav className={`nav ${isNavOpen ? 'nav-open' : ''}`} ref={navRef}>
        <ul className="nav-links">
          <li>
            <Link to="/" onClick={() => handleNavigation(onHomeClick)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/" onClick={() => handleNavigation(onServicesClick)}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/" onClick={() => handleNavigation(onAboutMeClick)}>
              About Me
            </Link>
          </li>
          <li>
            <Link to="/" onClick={() => handleNavigation(onWorkExperienceClick)}>
              Why Me?
            </Link>
          </li>
        </ul>
      </nav>

      {/* Let's Talk button */}
      <div className="button-container">
        <a
          href="https://wa.me/923114620040?text=Hello!%20I'm%20interested%20in%20your%20services.%20Can%20we%20talk?"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-header"
        >
          Let's Talk..
        </a>
      </div>
    </header>
  );
}

export default Header;
