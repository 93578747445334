import React from 'react';
import { Helmet } from 'react-helmet';
import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../assets/lotie_pic.json';
import { Link } from 'react-router-dom';

import '../css/Home.css';

// Assets png's
import fiverr from '../assets/fiverr.webp';
import linkein from '../assets/linkein.webp';
import upwork from '../assets/upwork.webp';
import whatsapp from '../assets/whatsapp.webp';
import github from '../assets/github.webp';


// Profile pic
import profile from '../assets/pic.webp';

// Support pic
import fast from '../assets/fast.webp';
import satis from '../assets/satis.webp';
import support from '../assets/support.webp';

import VE from '../assets/VE.webp';
import WA from '../assets/WA.webp';
import WP from '../assets/WP.webp';
import SW from '../assets/SW.webp';
import LA from '../assets/logo-animation.webp';
import PV from '../assets/promotion.webp';



function Home({ homeRef, servicesRef, aboutMeRef, workExperienceRef }) {
  return (
    <div className="home" ref={homeRef}>
      <Helmet>
        <title>Usman Sultan - Animator, Web Developer & Blockchain Expert</title>
        <meta name="description" content="A dedicated professional skilled in animation, web development, and blockchain technology." />
        <link rel="stylesheet" href="../css/Home.css" /> {/* Add your CSS file link here */}
      </Helmet>
      <div className="upper-section">
        <div className="text-container">
          <h1>
            <span className="heading-highlight">Usman Sultan</span>
            <br />
            Animator, Web App Dev, Blockchain Dev.
          </h1>
          <p>
            A dedicated professional with a talent for crafting innovative web solutions, combining technical prowess in front-end development and blockchain technology with a creative edge in animation. Delivering exceptional results through a blend of technical expertise and artistic vision.
          </p>
          <div className="social-icons">
            <a href="https://www.upwork.com/" target="_blank" rel="noopener noreferrer" className="icon upwork">
              <img src={upwork} alt="Upwork" className="icon-image" />
            </a>
            <a href="https://www.fiverr.com/" target="_blank" rel="noopener noreferrer" className="icon fiverr">
              <img src={fiverr} alt="Fiverr" className="icon-image" />
            </a>
            <a href="https://www.linkedin.com/in/usman-khan-648ab0209/" target="_blank" rel="noopener noreferrer" className="icon linkedin">
              <img src={linkein} alt="LinkedIn" className="icon-image" />
            </a>
            <a href="https://github.com/usmansultan7" target="_blank" rel="noopener noreferrer" className="icon whatsapp">
              <img src={github} alt="Github" className="icon-image" />
            </a>
            <a href="https://wa.me/923114620040?text=Hello!%20I'm%20interested%20in%20your%20services.%20Can%20we%20talk?" target="_blank" rel="noopener noreferrer" className="icon whatsapp">
              <img src={whatsapp} alt="WhatsApp" className="icon-image" />
            </a>
          </div>
        </div>
        <div className="image-container">
          <Player
            autoplay
            loop
            src={animationData}
            className="lottie-background"
          />
          <img src={profile} alt="Visual Representation" className="profile-image" />
        </div>
      </div>

      <div className="lower-section">
        <div className="icon-section">
          <img src={fast} alt="Fast Delivery" className="icon-image" />
          <p>Fast Delivery</p>
        </div>
        <div className="icon-section">
          <img src={satis} alt="100% Satisfaction" className="icon-image" />
          <p>100% Satisfaction</p>
        </div>
        <div className="icon-section">
          <img src={support} alt="24/7 Support" className="icon-image" />
          <p>Quick Response </p>
        </div>
      </div>

      <div id="services-section" className="skills-section" ref={servicesRef}>
        <h2>Our Services</h2>
        <div className="skills-grid">
          <div className="skills-row">
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={VE} alt="Motion Graphics" className="skill-image" />
                  <Link to="/motion-graphics" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Motion Graphics</h3>
                  <p>Create dynamic and engaging motion graphics with expert After Effects animation.</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={WA} alt="Web Application" className="skill-image" />
                  <Link to="/explore/Web Application" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Web Application</h3>
                  <p>Develop responsive and interactive web applications using the powerful React framework.</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={LA} alt="Logo animation" className="skill-image" />
                  <Link to="/motion-graphics" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Logo Animation</h3>
                  <p>Bring your brand to life with captivating professional logo animations that leave a lasting impression</p>
                </div>
              </div>
            </div>
          </div>
          <div className="skills-row">
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={PV} alt="Promotional Video" className="skill-image" />
                  <Link to="/promotional" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>Promotional Video</h3>
                  <p>Elevate your brand with stunning and engaging promotional videos designed to captivate your audience</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={WP} alt="WordPress eCommerce Store" className="skill-image" />
                  <Link to="/explore/Wordpress" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>WordPress eCommerce Store</h3>
                  <p>Build and customize fully functional eCommerce stores with WordPress for seamless online sales.</p>
                </div>
              </div>
            </div>
            <div className="skill-column">
              <div className="skill-content">
                <div className="skill-button">
                  <img src={SW} alt="SolidWorks" className="skill-image" />
                  <Link to="/solidworks" className="btn-contact">Explore</Link>
                </div>
                <div className="skill-info">
                  <h3>SolidWorks</h3>
                  <p>Design and create precise 3D models with SolidWorks for engineering and product development.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="who-am-i-section">
        <h2>Who Is Usman Sultan?</h2>
        <div className="who-am-i-content">
          <div className="about-me" ref={aboutMeRef}>
            <div className="about-me-section">
              <h2>About Me</h2>
              <p>Hello! I'm Usman Sultan, I have a diverse skill set spanning various programming languages, libraries, frameworks, and animation tools. Here’s a bit more about my expertise:</p>

              <div className="education">
                <h2>Education</h2>
                <p>I graduated with a degree in Computer Science from AIR University. During my time there, I developed an Ethereum blockchain web application as my final year project, which fueled my passion for blockchain development.</p>
              </div>
              <div className="work-experience" ref={workExperienceRef}>
                <h2>Work Experience</h2>
                <p>With over four years in animation and six months in web development, I specialize in creating compelling ads and promotional content. My work combines advanced tools like Adobe After Effects, Blender, and Spline to deliver striking 3D visuals and interactive web experiences. Whether you need eye-catching ads or a standout portfolio, my unique blend of skills can help bring your vision to life.</p>
              </div>
              <div className="skills">
                <h3>Programming Languages</h3>
                <ul>
                  <li>C++</li>
                  <li>JavaScript</li>
                  <li>Solidity</li>
                </ul>
              </div>

              <div className="libraries">
                <h3>Libraries</h3>
                <ul>
                  <li>React.js</li>
                  <li>Ethers.js</li>
                </ul>
              </div>

              <div className="frameworks">
                <h3>Frameworks</h3>
                <ul>
                  <li>Node.js</li>
                  <li>Hardhat.js</li>
                </ul>
              </div>

              <div className="animation-tools">
                <h3>Animation Tools</h3>
                <ul>
                  <li>Adobe After Effects</li>
                  <li>Adobe Photoshop</li>
                  <li>Adobe Audition</li>
                  <li>Blender (currently learning)</li>
                  <li>Spline (currently learning)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
